// Generated by Framer (a1bdbac)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/534oB9Tn5cZB6VZbkWLJ/Video.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["TIIgcviCs", "JXFpM0YTl", "xrvQQ2By8"];

const variantClassNames = {JXFpM0YTl: "framer-v-1m6x8m6", TIIgcviCs: "framer-v-1nark05", xrvQQ2By8: "framer-v-y35vag"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "TIIgcviCs", Mobile: "xrvQQ2By8", Tablet: "JXFpM0YTl"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "TIIgcviCs", image: MbUNZO1Dp = {src: new URL("assets/1024/RainplvGVoa8Bicv1zXFb4qGGM.png", import.meta.url).href, srcSet: `${new URL("assets/512/RainplvGVoa8Bicv1zXFb4qGGM.png", import.meta.url).href} 512w, ${new URL("assets/1024/RainplvGVoa8Bicv1zXFb4qGGM.png", import.meta.url).href} 1024w, ${new URL("assets/2048/RainplvGVoa8Bicv1zXFb4qGGM.png", import.meta.url).href} 2048w, ${new URL("assets/RainplvGVoa8Bicv1zXFb4qGGM.png", import.meta.url).href} 2397w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "TIIgcviCs", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sAiM2", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", ...toResponsiveImage(MbUNZO1Dp)}} className={cx("framer-1nark05", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"TIIgcviCs"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({JXFpM0YTl: {"data-framer-name": "Tablet"}, xrvQQ2By8: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1e36mmb-container"} layoutDependency={layoutDependency} layoutId={"jp6tbxsmA-container"} transition={transition}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={15} bottomLeftRadius={15} bottomRightRadius={15} canvasPlay={false} controls={false} height={"100%"} id={"jp6tbxsmA"} isMixedBorderRadius={false} layoutId={"jp6tbxsmA"} loop muted objectFit={"cover"} playing posterEnabled={false} srcFile={new URL("assets/tctarbBKod9ACJCLMUhLIodGY8I.mp4", import.meta.url).href} srcType={"Upload"} srcUrl={"https://assets.mixkit.co/videos/preview/mixkit-ice-cream-glass-of-red-soda-5094-small.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={15} topRightRadius={15} volume={25} width={"100%"}/></motion.div></Image>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sAiM2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sAiM2 * { box-sizing: border-box; }", ".framer-sAiM2 .framer-wi8zsd { display: block; }", ".framer-sAiM2 .framer-1nark05 { height: 707px; position: relative; width: 978px; }", ".framer-sAiM2 .framer-1e36mmb-container { flex: none; height: 89%; left: calc(50.30674846625769% - 92.22903885480572% / 2); position: absolute; top: calc(49.96461429582451% - 88.60580325548479% / 2); width: 92%; }", ".framer-sAiM2.framer-v-1m6x8m6 .framer-1nark05 { aspect-ratio: 1.3839491438184626 / 1; height: var(--framer-aspect-ratio-supported, 463px); width: 641px; }", ".framer-sAiM2.framer-v-1m6x8m6 .framer-1e36mmb-container, .framer-sAiM2.framer-v-y35vag .framer-1e36mmb-container { height: 89%; top: calc(49.96461429582451% - 89% / 2); }", ".framer-sAiM2.framer-v-y35vag .framer-1nark05 { aspect-ratio: 1.3839491438184626 / 1; height: var(--framer-aspect-ratio-supported, 253px); width: 350px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 706.5
 * @framerIntrinsicWidth 978
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"JXFpM0YTl":{"layout":["fixed","fixed"]},"xrvQQ2By8":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"MbUNZO1Dp":"image"}
 */
const Framera5gKWQ24D: React.ComponentType<Props> = withCSS(Component, css, "framer-sAiM2") as typeof Component;
export default Framera5gKWQ24D;

Framera5gKWQ24D.displayName = "video";

Framera5gKWQ24D.defaultProps = {height: 706.5, width: 978};

addPropertyControls(Framera5gKWQ24D, {variant: {options: ["TIIgcviCs", "JXFpM0YTl", "xrvQQ2By8"], optionTitles: ["Desktop", "Tablet", "Mobile"], title: "Variant", type: ControlType.Enum}, MbUNZO1Dp: {__defaultAssetReference: "data:framer/asset-reference,RainplvGVoa8Bicv1zXFb4qGGM.png?originalFilename=Group+147+1.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framera5gKWQ24D, [...VideoFonts])